:root {
    --document-width: 100%;
    --document-height: 100%;
    --cloud-top-position: 50px;
}

a {
    text-decoration: none;
    color: #000;
}

html,
body,
#root {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 3px;
}

body {
    background-color: #171c24;
}

#root {
    position: relative;
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
}

.container .header {
    background-color: #222b36;
    opacity: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: rotateIn 1s 0.5s forwards;
    z-index: 9999;
}
.container .header .name {
    color: #fff;
    font-size: 42px;
    font-weight: bold;
}

.container .header .separator {
    margin-right: 15px;
    margin-left: 15px;
}

.container .header .job {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    width: 320px;
}

.container .container__separator {
    flex: 0 0 auto;
    opacity: 0;
    background-color: #c0392b;
    height: 10px;
    margin-top: 20px;
    width: 600px    ;
    animation: slideIn 1s 1s forwards;
}

.container .description {
    position: relative;
    opacity: 0;
    color: #fff;
    font-size: 25px;
    margin-top: 20px;
    height: 50px;
    animation: slideIn 1.5s 1.2s forwards;
    z-index: 9999;
}

.container .icon__bar, .container .language__bar .wrapper  {
    display: flex;
    align-content: center;
    justify-content: center;
}

.container .icon__bar {
    opacity: 0;
    margin-top: 50px;
    animation: slideInY 1.5s 1.2s linear forwards;
    z-index: 9999;
}

.container .language__bar {
    opacity: 0;
    color: #fff;
    animation: slideInY 1.5s 1.2s linear forwards;
    z-index: 9999;
}

.container .language__bar p {
    font-size: 20px;
}

.container .icon {
    width: 64px;
    height: 64px;
    z-index: 9999;
}

.container .language__bar .wrapper .icon {
    width: 50px;
    height: 50px;
}

.container .icon__small {
    width: 50px;
    height: 50px;
}

.container .white-round {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: #fff;
    border-radius: 50%;
    font-size: 25px;
    font-weight: bold;
    z-index: 9999;
}

.container .icon__bar *:not(:first-child), .container .language__bar .wrapper *:not(:first-child) {
    margin-left: 15px;
}

.container img.icon,
.container .white-round {
    transition: transform 1s;
}

.container img.icon:hover,
.container .white-round:hover {
    transform: scale(1.3);
}

.moon {
    display: block;
    opacity: 1;
    top: 50%;
    left: 15%;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: transform 1s;
}

.moon:hover {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(360deg) scale(1.2)
}

.moon img {
    width: 400px;
    height: 400px;
    z-index: 1;
}

.cloud {
    --cloud-travel-width: var(--document-width);
    --cloud-animation-duration: 7s;
    --cloud-animation-delay: 7s;
    display: block;
    top: var(--cloud-top-position);
    opacity: 0;
    position: absolute;
    animation: cloudPassBy var(--cloud-animation-duration) var(--cloud-animation-delay) linear;
}

.cloud img {
    --cloud-size: 128px;
    width: var(--cloud-size);
    height: var(--cloud-size);
}

.rocket {
    --top-position: 50px;
    --bottom-position: 200px;
    --rocket-travel-width: 300px;
    position: absolute;
    display: block;
    opacity: 0;
    top: 0;
    left: 0;
    animation: rocketPassBy 15s 3s linear infinite, rocketReactorFLash 0.5s 3s linear infinite;
}

.rocket img {
    width: 256px;
    height: 256px;
}

@media (min-width: 1920px) {
    .cloud {
        --cloud-travel-width: 400px;
    }
}

@media (min-width: 1201px) {
    .moon img {
        filter: drop-shadow(0 0 1.25rem #95a5a6);
    }
}


@media (min-width: 1201px) and (max-width: 1920px) {
    .moon {
        left: 10%;
        top: 20%;
    }
    .moon img {
        width: 300px;
        height: 300px;
    }

    .rocket {
        --top-position: 0px;
        --bottom-position: 100px;
        --rocket-travel-width: 175px;
    }
    .rocket img {
        width: 128px;
        height: 128px;
    }

    .cloud {
        --cloud-animation-duration: 15s;
    }
}

@media (max-width: 1201px) {
    .moon {
        top: 85%;
        left: 10%;
    }
    .moon img {
        width: 200px;
        height: 200px;
    }

    .rocket {
        --top-position: calc(var(--document-height) - 180px);
        --bottom-position: calc(var(--document-height) - 100px);
        --rocket-travel-width: 300px;
    }
    .rocket img {
        width: 64px;
        height: 64px;
    }

    .cloud {
        --cloud-animation-duration: 12s;
    }
}

@media (max-width: 768px) {
    .moon {
        top: 90%;
        left: 10%;
    }

    .container .header .name {
        font-size: 38px;
    }

    .container .header .job {
        font-size: 25px;
        width: 255px;
    }

    .container .container__separator {
        margin-top: 20px;
        width: 400px;
    }

    .container .description {
        font-size: 20px;
        margin-top: 20px;
        height: 40px;
    }

    .container .icon__bar {
        margin-top: 30px;
    }

    .container .language__bar p {
        font-size: 16px;
    }

    .container .icon, .container .white-round {
        width: 48px;
        height: 48px;
    }

    .container .icon__small {
        width: 35px;
        height: 35px;
    }

    .container .icon__bar *:not(:first-child), .container .language__bar .wrapper *:not(:first-child) {
        margin-left: 10px;
    }

    .cloud {
        --cloud-animation-duration: 12s;
    }

    .cloud img {
        width: calc(var(--cloud-size) / 2);
        height: calc(var(--cloud-size) / 2);
    }

    .rocket {
        --top-position: 10px;
        --bottom-position: calc(var(--document-height) - 80px);
        --rocket-travel-width: var(--document-width);
    }
}

@media (max-width: 580px) {
    .container .header .name {
        font-size: 30px;
    }

    .container .header .job {
        font-size: 18px;
        width: 150px;
    }

    .container .container__separator {
        width: 380px;
    }

    .container .description {
        font-size: 14px;
    }

    .cloud {
        --cloud-animation-duration: 10s;
    }
}

@media (max-width: 430px) {
    .container .header .name {
        font-size: 20px;
    }

    .container .header .job {
        font-size: 16px;
        width: 130px;
    }

    .container .container__separator {
        margin-top: 20px;
        width: 300px;
    }

    .container .description {
        font-size: 15px;
        margin-top: 20px;
        height: 40px;
    }

    .container .icon__bar {
        margin-top: 30px;
    }

    .container .icon, .container .white-round  {
        width: 40px;
        height: 40px;
    }

    .container .description {
        font-size: 14px;
    }

    .container .icon__bar *:not(:first-child), .container .language__bar .wrapper *:not(:first-child) {
        margin-left: 8px;
    }

    .container .icon__small {
        width: 32px;
        height: 32px;
    }

    .cloud {
        --cloud-animation-duration: 8s;
    }
}

@keyframes scaleUp {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.2);
    }
}

@keyframes rotateIn {
    from {
        opacity: 0;
        transform: rotate(0deg);
    }
    to {
        opacity: 1;
        transform: rotate(360deg);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes slideInY {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes slideInYTop {
    from {
        opacity: 0;
        transform: translateY(-100%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes moonFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes cloudPassBy {
    from {
        opacity: 0;
        transform: translateX(-128px);
    }
    20%,
    80% {
        opacity: 0.8;
    }
    to {
        opacity: 0;
        transform: translateX(calc(var(--cloud-travel-width) + 128px));
    }
}

@keyframes rocketPassBy {
    from {
        opacity: 1;
        z-index: 0;
        transform: translate(-100%, var(--top-position)) rotate(90deg) scale(0.5);
    }
    25% {
        opacity: 1;
        z-index: 0;
        transform: translate(calc(var(--rocket-travel-width) / 2), var(--top-position)) rotate(90deg) scale(0.5);
    }
    50% {
        opacity: 0;
        z-index: 0;
        transform: translate(calc(var(--rocket-travel-width) + 100%), var(--top-position)) rotate(90deg) scale(0.5);
    }
    51% {
        opacity: 0;
        z-index: 99;
        transform: translate(calc(var(--rocket-travel-width) + 100%), var(--bottom-position)) rotate(-90deg) scale(0.5);
    }
    75% {
        opacity: 1;
        z-index: 99;
        transform: translate(calc(var(--rocket-travel-width) / 2), var(--bottom-position)) rotate(-90deg) scale(1);
    }
    to {
        opacity: 1;
        z-index: 99;
        transform: translate(-100%, var(--bottom-position)) rotate(-90deg) scale(0.5);
    }
}

@keyframes rocketReactorFLash {
    from {
        filter: drop-shadow(0 10px 6px #e74c3c);
    }
    to {
        filter: drop-shadow(0 10px 3px #e67e22);
    }
}
